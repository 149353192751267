<script>
export default {
	name: 'Image',
	props: {
		sources: {
			type: Array,
			required: true
		},
		defaultSrc: {
			type: String,
			required: true
		},
		width: {
			type: Number,
			required: false
		},
		height: {
			type: Number,
			required: false
		},
		loading: {
			type: String,
			default: 'lazy'
		},
		decoding: {
			type: String,
			default: 'async'
		},
		alt: {
			type: String,
			default: ''
		},
		backgroundImage: {
			type: String,
			default: ''
		}
	}
}
</script>

<template lang="html">
	<div class="image-container">
		<picture :style="{ backgroundImage }">
			<source
				v-for="source in sources"
				:key="source.srcset"
				:srcset="source.srcset"
				:sizes="source.sizes"
				:type="source.type"
			>
			<img
				:src="defaultSrc"
				:width="width"
				:height="height"
				:loading="loading"
				:decoding="decoding"
				:alt="alt"
			>
		</picture>
	</div>
</template>

<style lang="scss" scoped>
.image-container {
	width: 100%; height: 100%;

	picture {
		display: flex;
		width: 100%; height: 100%;
		background-position: 50%;
		background-size: cover;
	}

	img {
		width: 100%; height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&[data-parallax] {
		position: relative;
		overflow: hidden;
	}
}
</style>