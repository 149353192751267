import gsap from 'gsap'

const preloader = {
	tlPreloader: null,
	preloaderClass: '.preloader',
	svgPreloaderClass: '.svg-preloader',
	pathBgClass: '.path-bg',
	pathsAnimClass: '.path-anim',
	animation: function() {
		this.pathAnimClass = gsap.utils.toArray(this.pathsAnimClass)

		this.tlPreloader = gsap.timeline()

		this.tlPreloader
			.to(this.pathBgClass, {
				delay: .8,
				autoAlpha: 1,
				duration: 1.5,
				ease: 'power2.out'
			})
			.to(this.pathAnimClass, {
				css: {
					animationPlayState: 'paused'
				}
			}, '-=.1')
			.to(this.svgPreloaderClass, {
				onStart: () => {
					document.querySelector(this.svgPreloaderClass).classList.add('svg-preloader--done')
				}
			}, '-=.3')
			.to(this.preloaderClass, {
				delay: .3,
				ease: 'power4.in',
				duration: .3,
				autoAlpha: 0,
				onStart: () => {
					document.body.style.backgroundColor = null
				},
				onComplete: () => {
					document.body.classList.replace('is-loading', 'is-loaded')
					document.querySelector('.preloader').remove()
				}
			})
	},
	totalDuration: function() {
		return this.tlPreloader.totalDuration() * 1000
	}
}

export {
	preloader
}
