import gsap from 'gsap'

const spinner = {
	bodyClass: document.body,
	tlSpinner: gsap.timeline(),
	el: document.createElement('div'),
	spinnerClass: '.spinner',
	start: function() {
		this.el.setAttribute('class', 'spinner')
		this.bodyClass.insertAdjacentElement('beforeend', this.el)

		if(!this.el) return

		this.tlSpinner
			.to(this.spinnerClass, {
				delay: .5,
				autoAlpha: .25,
				duration: 1,
				ease: 'power4.in'
			})
	},
	end: function() {
		if(!this.el) return

		this.tlSpinner
			.to(this.spinnerClass, {
				autoAlpha: 0,
				duration: 1,
				ease: 'power4.out',
				onComplete: () => {
					this.el.remove()
				}
			})
	}
}

export {
	spinner
}