<script>
import { ref } from 'vue'

export default {
	name: 'Icon',
	props: {
		id: {
			type: String,
			required: true
		}
	},
	setup(props) {
		const idRef = ref(props.id)

		return {
			idRef
		}
	}
}
</script>

<template lang="html">
	<svg class="svg-icon">
		<use :xlink:href="`#${idRef}`"></use>
	</svg>
</template>