import { createApp } from 'vue'

import App from './App.vue'
import router from './router/'
import store from './store/'

import AppButton from 'btn-kit';

const app = createApp(App)

app.use(router())
app.use(store)
app.component('AppButton', AppButton);
app.mount('#app')
