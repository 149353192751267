<script>
import { onMounted } from 'vue'

import TextFields from 'text-fields'
import { MaskInput } from 'maska'

import Icon from './Icon.vue'

export default {
	name: 'CallForm',
	components: {
		Icon
	},
	setup() {
		onMounted(() => {
			new TextFields()
			new MaskInput('[data-maska]')
		})
	}
}
</script>

<template lang="html">
	<div class="call-form">
		<div class="text-field-container">
			<div class="input input--large">
				<label for="user-name" class="floating-label">Пожалуйста представьтесь</label>

				<input
					type="text"
					id="user-name"
				>
			</div>
		</div>

		<div class="text-field-container">
			<div class="input input--large">
				<label for="user-phone" class="floating-label">Укажите номер телефона</label>

				<input
					data-maska="+7 (###) ###-##-##"
					placeholder="+7 (900) 123 45 67"
					type="text"
					id="user-phone"
				>
			</div>
		</div>

		<div class="btn-group btn-group--left">
			<AppButton
				shape="rounded"
				size="lg"
				theme="blue"
			>
				<span class="label">Перезвоните мне</span>
			</AppButton>
		</div>
	</div>
</template>

<style lang="scss">
@import "../assets/scss/base/mixins";
@import "node_modules/text-fields/src";

.call-form {
	display: flex;
	flex-direction: column;

	>:not(:last-child) {
		margin-bottom: 3rem;
	}
}

.text-field-container {
	// Colors
	--tf-c-text-field: var(--c-text-rich);
	--tf-c-text-helper: SlateGrey;
	--tf-c-inactive: DarkGray;
	--tf-c-focused: DodgerBlue;
	--tf-c-activated: Gray;
	--tf-c-hover: DimGray;
	--tf-c-error: Crimson;
	--tf-c-disabled: Silver;
	--tf-c-placeholder: Silver;
	--tf-c-border: var(--tf-c-inactive); // redefined when manipulating text fields

	// Font
	--tf-font-family: 'Inter', sans-serif;
	--tf-font-size-field: 16px;
	--tf-font-size-helper: 12px;
	--tf-font-weight: 400;
	--tf-letter-spacing: .009375em;
	--tf-input-line-height: 23px;
	--tf-textarea-line-height: 24px;

	// Border
	--tf-border-radius: 2.5px;

	// Transition
	--tf-transition-easing: cubic-bezier(0, 0.202, 0.204, 1);
}
</style>