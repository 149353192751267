<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Header from './Header.vue'
import Footer from './Footer.vue'
import Overlay from '../components/Overlay.vue'
import Drawer from '../components/Drawer.vue'
import ModalCall from '../components/ModalCall.vue'
import Cursor from '../components/Cursor.vue'

export default {
	name: 'Layout',
	components: {
		Header,
		Footer,
		Overlay,
		Drawer,
		ModalCall,
		Cursor
	},
	setup() {
		const store = useStore()
		const isPageReady = computed(() => store.state.isPageReady)

		return {
			isPageReady
		}
	}
}
</script>

<template>
	<Header />
	<slot />
	<Footer v-if="isPageReady" />
	<Drawer />
	<Overlay />
	<ModalCall />
	<Cursor />
</template>