<script>
import { ref, onMounted } from 'vue'

import gsap from 'gsap'
import FollowMouse from 'follow-mouse'
import MediaTrigger from 'media-trigger'
import { mediaTouch } from '../utils/matchMedia'

FollowMouse.registerGSAP(gsap)

export default {
	name: 'Cursor',
	setup() {
		const follower = ref(null)

		const createFollower = () => {
			follower.value = new FollowMouse({
				ease: 0.12
			})
		}

		const destroyFollower = () => {
			if (follower.value) {
				follower.value.destroy()
				follower.value = null
			}
		}

		onMounted(() => {
			new MediaTrigger({
				media: mediaTouch,
				entry: createFollower,
				exit: destroyFollower
			})
		})

		return {
			follower
		}
	}
}
</script>

<template lang="html">

</template>

<style lang="scss">
.cursor-follower {
	pointer-events: none;
	position: fixed;
	top: 0; left: 0;
	z-index: var(--z-index-follower);
	contain: layout style size;
	mix-blend-mode: exclusion;

	.cursor-style {
		position: absolute;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		width: var(--size, 10px); height: var(--size, 10px);
		background-color: var(--background-color, transparent);
		transition: background-color 225ms ease,
		width 225ms ease,
		height 225ms ease;

		&--default {
			--size: 10px;
		}

		&--carousel {
			--size: 70px;

			background:
				url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Cpath fill='%23fff' d='M11.657 11.352a1.14 1.14 0 0 0-1.648 0l-7.737 7.972a.976.976 0 0 0 0 1.352l7.737 7.972a1.14 1.14 0 0 0 1.648 0 1.224 1.224 0 0 0 0-1.698l-6.74-6.955 6.749-6.954a1.221 1.221 0 0 0-.01-1.689ZM28.343 28.648a1.14 1.14 0 0 0 1.648 0l7.737-7.972a.976.976 0 0 0 0-1.352l-7.737-7.972a1.14 1.14 0 0 0-1.648 0 1.224 1.224 0 0 0 0 1.698l6.74 6.955-6.749 6.954a1.221 1.221 0 0 0 .01 1.689Z'/%3E%3C/svg%3E")
				center / 40px 40px
				no-repeat
				#111;
		}

		&--big-bubble {
			--background-color: #eee;
			--size: 100px;
		}

		//&--lnk-tab {
		//	--background-color: white;
		//	--size: 82px;
		//}
		//
		//&--lnk-big-drawer {
		//	--background-color: white;
		//	--size: 102px;
		//}
		//
		//&--footer-mail {
		//	--background-color: white;
		//	--size: 12em;
		//}

		&--hidden {
			--background-color: var(--c-tertiary);
			--size: 6px;
		}
	}
}
</style>