<script>
import { ref, watch, nextTick, onMounted, onBeforeUnmount, computed } from 'vue'
import { useStore } from 'vuex'

import gsap from 'gsap'
import { mediaTouch, mediaBreakpoint } from '../utils/matchMedia'
import { lock, unlock } from 'tua-body-scroll-lock'
import { scroll } from '../utils/scroll'
import ToggleTheme from 'vue-toggle-theme'
import MediaTrigger from 'media-trigger'

import Hamburger from '../components/Hamburger.vue'
import Icon from './Icon.vue'

export default {
	name: 'Drawer',
	components: {
		Hamburger,
		ToggleTheme,
		Icon
	},
	setup() {
		const isShowDrawer = ref(false)
		const isMobile = ref(false)
		const drawerScrollerRef = ref()
		const drawerNavMainRef = ref()
		const drawerNavIconRef = ref()
		const drawerContactRef = ref()

		const store = useStore()

		const isDrawerVisible = computed(() => store.state.isDrawerVisible)
		const overlayDuration = computed(() => store.state.overlayDuration)

		let tlDrawerOpen
		let tlDrawerClose

		const drawerAnimation = () => {
			tlDrawerOpen = gsap.timeline({ paused: true })
			tlDrawerClose = gsap.timeline({ paused: true })

			tlDrawerOpen
				.from(drawerNavMainRef.value.children, {
					autoAlpha: 0,
					y: isMobile.value ? 40 : 100,
					duration: 0.5,
					ease: 'sine.out',
					stagger: 0.1
				})
				.from(drawerNavIconRef.value.children, {
					autoAlpha: 0,
					y: isMobile.value ? 30 : 60,
					duration: 0.5,
					ease: 'sine.out',
					stagger: 0.05
				}, '> -0.4')
				.from(drawerContactRef.value, {
					autoAlpha: 0,
					y: isMobile.value ? 30 : 60,
					duration: 0.5,
					ease: 'sine.out'
				}, '> -0.6')

			tlDrawerClose
				.to(drawerNavMainRef.value.children, {
					autoAlpha: 0,
					y: isMobile.value ? -40 : -80,
					duration: 0.3,
					ease: 'sine.in',
					stagger: 0.05
				})
				.to(drawerNavIconRef.value.children, {
					autoAlpha: 0,
					y: isMobile.value ? -30 : -60,
					duration: 0.3,
					ease: 'sine.in',
					stagger: 0.05
				}, '< +=0.2')
				.to(drawerContactRef.value, {
					autoAlpha: 0,
					y: isMobile.value ? -30 : -60,
					duration: 0.3,
					ease: 'sine.in'
				}, '<')
		}

		const drawerOpen = () => {
			nextTick(() => {
				drawerAnimation()
			})

			setTimeout(() => { tlDrawerOpen.timeScale(1).restart() }, overlayDuration.value / 1.2)

			isShowDrawer.value = true

			document.body.classList.add('is-drawer')

			if (window.matchMedia(mediaTouch).matches) {
				scroll.smooth.stop()
			} else {
				setTimeout(() => { lock(drawerScrollerRef.value) }, 50)
			}
		}

		const drawerClose = () => {
			tlDrawerClose.timeScale(1).restart()

			setTimeout(() => { isShowDrawer.value = false }, overlayDuration.value)

			setTimeout(() => { document.body.classList.remove('is-drawer') }, overlayDuration.value - 500)

			if (window.matchMedia(mediaTouch).matches) {
				scroll.smooth.start()
			} else {
				unlock(drawerScrollerRef.value)
			}
		}

		watch(isDrawerVisible, (newValue, oldValue) => {
			oldValue && !newValue ? drawerClose() : drawerOpen()
		})

		onMounted(() => {
			new MediaTrigger({
				media: mediaBreakpoint,
				entry: () => {
					isMobile.value = false
				},
				exit: () => {
					isMobile.value = true
				}
			})
		})

		onBeforeUnmount(() => {
			tlDrawerOpen.kill()
			tlDrawerClose.kill()
		})

		return {
			isShowDrawer,
			drawerNavMainRef,
			drawerNavIconRef,
			drawerContactRef,
			drawerScrollerRef
		}
	}
}
</script>

<template lang="html">
	<Teleport to="body">
		<Hamburger />

		<div v-if="isShowDrawer" class="drawer" role="dialog" data-lenis-prevent>
			<div class="drawer__scroller" ref="drawerScrollerRef">
				<div class="drawer-content">
					<div class="drawer-content__menu">
						<nav ref="drawerNavMainRef" class="drawer-nav drawer-nav--main">
							<router-link class="link link-drawer-big" to="/">
								<span>Главная</span>
							</router-link>
							<router-link class="link link-drawer-big" to="/about">
								<span>О нас</span>
							</router-link>
							<router-link class="link link-drawer-big" to="/services">
								<span>Услуги</span>
							</router-link>
							<router-link class="link link-drawer-big" to="/catalog">
								<span>Каталог</span>
							</router-link>
							<router-link class="link link-drawer-big" to="/contact">
								<span>Контакты</span>
							</router-link>
						</nav>

						<nav ref="drawerNavIconRef" class="drawer-nav drawer-nav--icon">
							<router-link class="link link-drawer-icon" to="/repair-machines">
								<Icon id="i-precision_manufacturing_black_24dp" />
								<span>Диагностика и ремонт машин</span>
							</router-link>
							<router-link class="link link-drawer-icon" to="/repair-boards">
								<Icon id="i-memory_black_24dp" />
								<span>Диагностика и ремонт плат</span>
							</router-link>
							<router-link class="link link-drawer-icon" to="/delivery-parts">
								<Icon id="i-settings_suggest_black_24dp" />
								<span>Поставки запчастей</span>
							</router-link>
							<router-link class="link link-drawer-icon" to="/codes-parts">
								<Icon id="i-pin_black_24dp" />
								<span>Запчасти по кодам</span>
							</router-link>
							<router-link class="link link-drawer-icon" to="/serial-parts">
								<Icon id="i-tag_black_24dp" />
								<span>Запчасти по серийному номеру</span>
							</router-link>
						</nav>
					</div>

					<div ref="drawerContactRef" class="drawer-content__contact">
						<div class="drawer-content__contact-address">
							<Icon id="i-fmd_good_black_24dp" />
							<p class="paragraph-lead">
								<span class="text-small">2-я Синичкина улица 9Ас4, Москва, 111020</span>
							</p>
						</div>

						<div class="drawer-content__contact-phone">
							<Icon id="i-support_agent_black_24dp" />
							<p class="paragraph-lead">
								<span class="text-small">+7 (495) 139 30 30</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Teleport>
</template>

<style lang="scss" scoped>
@import '../assets/scss/base/mixins';

.drawer {
	position: fixed;
	inset: 0;
	z-index: var(--z-index-drawer);
	display: flex;
	width: 100vw; height: 100vh;

	&__scroller {
		position: relative;
		z-index: 1;
		width: 100%;
		height: 100%;

		overflow: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		transform: translateX(0);
	}
}

.drawer-content {
	@include layoutCenter;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	padding: 10rem 1rem;

	@include min-width(tablet-portrait) {
		padding: var(--headerHeight) 0 0;
	}

	.paragraph-lead {
		margin: 0;
		line-height: inherit;

		@include min-width(tablet-portrait) {
			line-height: 1;
		}
	}

	&__menu {
		flex: 1;
		display: flex;
		flex-direction: column;
		width: 100%;

		@include min-width(tablet-portrait) {
			flex-direction: row;
		}

		.drawer-nav {
			&--main {
				display: flex;
				align-items: flex-start;
				flex-direction: column;

				@include min-width(tablet-portrait) {
					width: 45%;
					padding-right: 8vw;
				}
			}

			&--icon {
				padding-top: 3.4rem;
				display: flex;
				align-items: flex-start;
				flex-direction: column;

				@include min-width(tablet-portrait) {
					width: 55%;
				}

				.link-drawer-icon {
					&:nth-child(3) {
						margin-bottom: 3.2rem;
					}
				}
			}
		}
	}

	&__contact {
		display: flex;
		flex-direction: column;
		padding: calc(4rem + 4vh) 0 10vh 3px;

		@include min-width(tablet-portrait) {
			flex-direction: row;
		}

		.svg-icon {
			margin-left: -3px;
			margin-right: 1.4rem;
			fill: var(--c-text-pale);
		}

		&-address {
			order: 2;
			display: flex;
			align-items: center;
			padding: 2rem 0 0 0;

			@include min-width(tablet-portrait) {
				order: 1;
				padding: 0;
			}
		}

		&-phone {
			order: 1;
			display: flex;
			align-items: center;

			@include min-width(tablet-portrait) {
				order: 2;
				padding: 0 0 0 calc(2rem + 4vw);
			}
		}
	}
}
</style>