<script>
import { ref, onMounted } from 'vue'
import MediaTrigger from 'media-trigger'

import Icon from '../components/Icon.vue'
import Logotype from '../components/Logotype.vue'

export default {
	name: 'Footer',
	components: {
		Icon,
		Logotype
	},
	setup() {
		const showFooterLogo = ref(true)
		const currentYear = ref(new Date().getFullYear())

		onMounted(() => {
			new MediaTrigger({
				media: '(min-width: 992px)',
				entry: () => {
					showFooterLogo.value = true
				},
				exit: () => {
					showFooterLogo.value = false
				}
			})
		})

		return {
			showFooterLogo,
			currentYear
		}
	}
}
</script>

<template lang="html">
	<footer class="footer">
		<div class="footer__section">
			<div class="footer-menu">
				<div class="footer-menu__logo" v-if="showFooterLogo">
					<Logotype />
				</div>
				<div class="footer-menu__nav">
					<div class="footer-menu__nav-item">
						<p class="paragraph-lead">
							<span class="text-large text-bold">Меню</span>
						</p>
						<nav class="footer-nav">
							<router-link to="/about" class="link link-different">
								<span class="text-small">О нас</span>
							</router-link>
							<router-link to="/services" class="link link-different">
								<span class="text-small">Услуги</span>
							</router-link>
							<router-link to="/catalog" class="link link-different">
								<span class="text-small">Каталог</span>
							</router-link>
							<router-link to="/contact" class="link link-different">
								<span class="text-small">Контакты</span>
							</router-link>
						</nav>
					</div>
					<div class="footer-menu__nav-item">
						<p class="paragraph-lead">
							<span class="text-large text-bold">Услуги</span>
						</p>
						<nav class="footer-nav">
							<router-link class="link link-different" to="/repair-machines">
								<span class="text-small">Диагностика и ремонт машин</span>
							</router-link>
							<router-link class="link link-different" to="/repair-boards">
								<span class="text-small">Диагностика и ремонт плат</span>
							</router-link>
							<router-link class="link link-different" to="/delivery-parts">
								<span class="text-small">Поставки запчастей</span>
							</router-link>
							<router-link class="link link-different" to="/codes-parts">
								<span class="text-small">Запчасти по кодам</span>
							</router-link>
							<router-link class="link link-different" to="/serial-parts">
								<span class="text-small">Запчасти по серийному номеру</span>
							</router-link>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<div class="footer__section">
			<div class="footer-contact">
				<div class="footer-contact__address">
					<Icon id="i-fmd_good_black_24dp" />
					<p class="paragraph-lead">
						<span class="text-small">2-я Синичкина улица 9Ас4, Москва, 111020</span>
					</p>
				</div>
				<div class="footer-contact__phone">
					<Icon id="i-support_agent_black_24dp" />
					<p class="paragraph-lead">
						<span class="text-small">+7 (495) 139 30 30</span>
					</p>
				</div>
			</div>
		</div>
		<div class="footer__section">
			<div class="footer-legality">
				<div class="footer-legality__copy">
					<p class="paragraph-lead">
						<span class="text-small">© {{ currentYear }} — Robotech</span>
					</p>
				</div>
				<div class="footer-legality__legal">
					<p class="paragraph-lead">
						<span class="text-small">
							<router-link class="link link-different" to="/ics">
								<span class="text-small">ИГК</span>
							</router-link>
						</span>
					</p>
					<p class="paragraph-lead">
						<span class="text-small">
							<router-link class="link link-different" to="/legal-info">
								<span class="text-small">Правовая информация</span>
							</router-link>
						</span>
					</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<style lang="scss" scoped>
@import '../assets/scss/base/mixins';

.footer {
	--altitude: 4rem;

	padding-bottom: calc(var(--altitude) / 2);

	@include min-width(tablet-portrait) {
		--altitude: 5rem;
	}

	@include min-width(tablet-landscape) {
		--altitude: 7rem;
	}

	.paragraph-lead {
		margin: 0;
		line-height: inherit;

		@include min-width(tablet-portrait) {
			line-height: 1;
		}
	}

	&__section {
		border-top: 1px solid var(--с-color-border-decor);
	}

	&-menu {
		@include layoutCenter;
		display: flex;
		justify-content: space-between;
		padding: var(--altitude) 0;

		&__logo {
			a {
				color: var(--c-text-rich);
			}
		}

		&__nav {
			display: flex;
			width: 100%;

			@include min-width(tablet-portrait) {
				width: auto;
			}

			.footer-nav {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-top: 2.5rem;

				.link {
					&:not(:last-child) {
						margin-bottom: 1rem;
					}
				}
			}

			&-item {
				width: auto;
				min-width: 35%;

				@include min-width(phone-portrait) {
					width: 50%;
					min-width: auto;
				}

				@include min-width(tablet-portrait) {
					width: auto;
				}

				&:not(:last-child) {
					margin-right: 3rem;

					@include min-width(tablet-portrait) {
						margin-right: calc(2rem + 10vw);
					}
				}
			}
		}
	}

	&-contact {
		@include layoutCenter;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding: calc(var(--altitude) / 2) 0;

		@include min-width(phone-landscape) {
			align-items: center;
			flex-direction: row;
		}

		.svg-icon {
			margin-left: -3px;
			margin-right: 1.4rem;
			fill: var(--c-text-pale);
		}

		&__address {
			display: flex;

			@include max-width(phone-landscape) {
				order: 2;
			}
		}

		&__phone {
			display: flex;

			@include max-width(phone-landscape) {
				padding-bottom: 1rem;
				order: 1;
			}
		}
	}

	&-legality {
		@include layoutCenter;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding: calc(var(--altitude) / 2) 0;

		@include min-width(phone-landscape) {
			align-items: center;
			flex-direction: row;
		}

		&__copy {
			@include max-width(phone-landscape) {
				order: 2;
			}
		}

		&__legal {
			display: flex;

			@include max-width(phone-landscape) {
				padding-bottom: 1rem;
				order: 1;
			}

			.paragraph-lead {
				&:not(:last-child) {
					margin-right: 2rem;
				}
			}
		}
	}
}
</style>
