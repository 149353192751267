import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'

export default () => {
	return createRouter({
		history: createWebHistory(),
		routes,
		scrollBehavior(to, from, savedPosition) {
			return new Promise((resolve) => {
				resolve({ top: 0 })
			})
		},
		linkActiveClass: 'active',
		linkExactActiveClass: 'exact-active'
	})
}