import { createStore } from 'vuex'

const store = createStore({
	state() {
		return {
			isPageReady: false,
			isDrawerVisible: false,
			isOverlayVisible: true,
			isHeaderDark: false,
			isModalCall: false,
			overlayDuration: 0
		}
	},
	mutations: {
		setPageReady(state, value) {
			state.isPageReady = value
		},
		setDrawerVisible(state, value) {
			state.isDrawerVisible = value
		},
		setOverlayVisible(state, value) {
			state.isOverlayVisible = value
		},
		setHeaderDark(state, value) {
			state.isHeaderDark = value
		},
		setModalCall(state, value) {
			state.isModalCall = value
		},
		setOverlayDuration(state, value) {
			state.overlayDuration = value
		}
	}
})

export default store