export const routes = [
	{
		path: '/',
		component: () => import('../views/Home.vue'),
		meta: {
			title: 'Robotech',
			description: 'Диагностика, ремонт и сервисное обслуживание ROBOPAC. Настраиваем машины и поставляем запчасти ROBOPAC.'
		}
	},
	{
		path: '/about',
		component: () => import('../views/About.vue'),
		meta: {
			title: 'Robotech • о компании',
			description: 'Robotech — высококвалифицированные и опытные профессионалы, которые помогут вам с современным упаковочным и складским оборудованием.'
		}
	},
	{
		path: '/services',
		component: () => import('../views/Services.vue'),
		meta: {
			title: 'Robotech • услуги',
			description: 'Наши специалисты помогут вам спланировать и провести техническое обслуживание машин производства ROBOPAC.'
		}
	},
	{
		path: '/catalog',
		component: () => import('../views/Catalog.vue'),
		meta: {
			title: 'Robotech • прайс',
			description: 'Остановка оборудования может привести к производственным потерям, а значит и к потерям финансовым.'
		}
	},
	{
		path: '/contact',
		component: () => import('../views/Contact.vue'),
		meta: {
			title: 'Robotech • контакты',
			description: ''
		}
	},
	{
		path: '/error',
		component: () => import('../views/Error.vue'),
		meta: {
			title: 'Robotech • 404',
			description: ''
		}
	},
	// not found
	{
		path: '/:pathMatch(.*)*',
		redirect: '/error'
	}
]
