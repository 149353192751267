<script>
import Icon from '../components/Icon.vue'

export default {
	name: 'Logotype',
	components: {
		Icon
	}
}
</script>

<template lang="html">
	<router-link
		class="logotype"
		to="/"
	>
		<div class="logotype__icon">
			<Icon id="i-logotype" />
		</div>
		<div class="logotype__text">
			Robotech
		</div>
	</router-link>
</template>

<style lang="scss" scoped>
@import '../assets/scss/base/mixins';

.logotype {
	display: inline-flex;
	align-items: center;

	&__icon {
		.svg-icon {
			width: 32px; height: 32px;
			fill: var(--c-secondary);
		}
	}

	&__text {
		padding-left: 1.6rem;
		line-height: 1;
		font-size: 1.4rem;
		font-weight: 800;

		@include min-width(tablet-landscape) {
			padding-left: 2rem;
			font-size: 2rem;
		}
	}
}
</style>
