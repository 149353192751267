import MediaTrigger from 'media-trigger'
import { mediaTouch } from './matchMedia'

const realVH = () => {
	const orientationChange = window.matchMedia('(orientation: portrait)')
	const vh = Math.round(window.innerHeight)

	const indicator= () => {
		document.documentElement.style.setProperty('--real-vh', ` ${vh}px`)
	}

	new MediaTrigger({
		media: mediaTouch,
		entry: () => {
			orientationChange.removeEventListener('change', indicator)
		},
		exit: () => {
			indicator()

			orientationChange.addEventListener('change', indicator)
		}
	})
}

export {
	realVH
}