<script>
import { computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'

import gsap from 'gsap'
import ShapeOverlays from 'shape-overlays'

ShapeOverlays.registerGSAP(gsap)

export default {
	name: 'Overlay',
	setup() {
		const store = useStore()

		const isOverlayVisible = computed(() => store.state.isOverlayVisible)

		let overlay = null

		const createOverlay = () => {
			overlay = new ShapeOverlays({
				svgClassName: '.svg-overlay',
				pathClassName: '.svg-overlay path',
				numberPoints: 3,
				delayPoints: 0.7,
				delayPaths: 0.1,
				duration: 0.6,
				ease: 'sine.inOut'
			})
		}

		watch(isOverlayVisible, (newValue, oldValue) => {
			oldValue && !newValue ? overlay.leave() : overlay.entry()

			store.commit('setOverlayDuration', overlay.totalDuration())
		})

		onMounted(() => {
			createOverlay()

			store.commit('setOverlayDuration', overlay.totalDuration())
		})

		return {
			isOverlayVisible
		}
	}
}
</script>

<template lang="html">
	<Teleport to="body">
		<svg class="svg-overlay" viewBox="0 0 100 100" preserveAspectRatio="none" tabindex="-1">
			<path :fill="`var(--c-overlay)`" d=""></path>
		</svg>
	</Teleport>
</template>

<style lang="scss" scoped>
.svg-overlay {
	pointer-events: none;
	width: 100%; height: 100%;
	position: fixed;
	top: 0; left: 0;
	z-index: var(--z-index-overlay);
}
</style>