<script>
import { ref, reactive, onMounted, watch, computed } from 'vue'
import { useStore } from 'vuex'

import MediaTrigger from 'media-trigger'
import ToggleTheme from 'vue-toggle-theme'
import { mediaBreakpoint } from '../utils/matchMedia'

import Icon from '../components/Icon.vue'
import Logotype from '../components/Logotype.vue'

export default {
	name: 'Header',
	components: {
		ToggleTheme,
		Icon,
		Logotype
	},
	setup() {
		const headerRef = ref(null)
		const showHeaderNav = ref(true)
		const store = useStore()

		const isHeaderDark = computed(() => store.state.isHeaderDark)

		const headerNavItems = reactive([
			{ text: 'О нас', to: '/about' },
			{ text: 'Услуги', to: '/services' },
			{ text: 'Каталог', to: '/catalog' },
			{ text: 'Контакты', to: '/contact' }
		])

		const handleClick = (navItem) => {
			navItem.selected = true

			setTimeout(() => {
				navItem.selected = false
			}, 1200)
		}

		watch(isHeaderDark, (newValue, oldValue) => {
			oldValue && !newValue ?
				headerRef.value.classList.remove('header--dark') :
				headerRef.value.classList.add('header--dark')
		})

		onMounted(() => {
			new MediaTrigger({
				media: mediaBreakpoint,
				entry: () => {
					showHeaderNav.value = true
				},
				exit: () => {
					showHeaderNav.value = false
				}
			})
		})

		return {
			showHeaderNav,
			headerNavItems,
			handleClick,
			headerRef
		}
	}
}
</script>

<template lang="html">
	<header class="header" ref="headerRef">
		<div class="header__inner">
			<div class="header__inner__logo">
				<Logotype />
			</div>
			<div class="header__inner__nav" v-if="showHeaderNav">
				<nav class="header-nav">
					<router-link
						v-for="(navItem, index) in headerNavItems"
						:key="index"
						:to="navItem.to"
						ref="headerNavItem"
						class="link link-header"
						:class="{ selected: navItem.selected }"
						@click="handleClick(navItem)"
					>
						<span>{{ navItem.text }}</span>
					</router-link>
				</nav>
			</div>
			<div class="header__inner__extra">
				<ToggleTheme class="theme" :defaultTheme="'light'" aria-label="Тема">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
						width="24px"
						height="24px"
						fill="none"
						class="theme-svg"
						viewBox="0 0 32 32"
					>
						<clipPath id="theme__path">
							<path d="M0-11h25a1 1 0 0017 13v30H0Z" />
						</clipPath>
						<g :clip-path="'url(#theme__path)'">
							<circle cx="16" cy="16" r="8.4" />
							<path
								d="M18.3 3.2c0 1.3-1 2.3-2.3 2.3s-2.3-1-2.3-2.3S14.7.9 16 .9s2.3 1 2.3 2.3zm-4.6 25.6c0-1.3 1-2.3 2.3-2.3s2.3 1 2.3 2.3-1 2.3-2.3 2.3-2.3-1-2.3-2.3zm15.1-10.5c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3zM3.2 13.7c1.3 0 2.3 1 2.3 2.3s-1 2.3-2.3 2.3S.9 17.3.9 16s1-2.3 2.3-2.3zm5.8-7C9 7.9 7.9 9 6.7 9S4.4 8 4.4 6.7s1-2.3 2.3-2.3S9 5.4 9 6.7zm16.3 21c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3 2.3 1 2.3 2.3-1 2.3-2.3 2.3zm2.4-21c0 1.3-1 2.3-2.3 2.3S23 7.9 23 6.7s1-2.3 2.3-2.3 2.4 1 2.4 2.3zM6.7 23C8 23 9 24 9 25.3s-1 2.3-2.3 2.3-2.3-1-2.3-2.3 1-2.3 2.3-2.3z"
							/>
						</g>
					</svg>
				</ToggleTheme>
			</div>
		</div>
	</header>
</template>

<style lang="scss" scoped>
@import '../assets/scss/base/mixins';

.header {
	--header-text-color: var(--c-text-pale);

	&--dark {
		--header-text-color: var(--c-text-contrast);
	}

	@include fixJumpingScrollbar;
	position: absolute;
	inset: 0 0 auto 0;
	z-index: 1;

	&__inner {
		border: 1px solid transparent;
		width: var(--wrapper);
		height: var(--headerHeight);
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;

		&__logo {
			flex: 1;

			a.logotype {
				color: var(--header-text-color);
			}
		}

		&__nav {
			padding-right: 11rem;
		}

		&__extra {
			padding-right: 6rem;

			@include min-width(tablet-landscape) {
				padding-right: 8rem;
			}

			.toggle-theme {
				background: none;

				svg {
					@media (hover: hover) and (pointer: fine) {
						&:hover {
							transition: fill 225ms ease;
							fill: var(--c-secondary);
						}
					}
				}
			}
		}
	}
}

.header-nav {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	&:hover {
		a {
			&.active {
				span {
					&:after {
						background-color: var(--header-text-color);
					}
				}
			}
		}
	}
}

.theme {
	--toggle-theme-duration: 500ms;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: 0;
	border: none;
	overflow: visible;
	text-transform: none;
	-webkit-appearance: button;
	-webkit-tap-highlight-color: transparent;
	background: none;
	width: 5rem; height: 5rem;

	.theme-svg {
		fill: var(--header-text-color);

		g circle,
		g path {
			transform-origin: center;
			transition: transform calc(var(--toggle-theme-duration) * 0.65) cubic-bezier(0, 0, 0, 1.25) calc(var(--toggle-theme-duration) * 0.35);
		}

		& :first-child path {
			transition-property: transform, d;
			transition-duration: calc(var(--toggle-theme-duration) * 0.6);
			transition-timing-function: cubic-bezier(0, 0, 0.5, 1);
		}
	}

	&-dark {
		.theme-svg {
			g path {
				transform: scale(0.75);
				transition-delay: 0s;
			}

			g circle {
				transform: scale(1.4);
				transition-delay: 0s;
			}

			& :first-child path {
				d: path("M-9 3h25a1 1 0 0017 13v30H0Z");
				transition-delay: calc(var(--toggle-theme-duration) * 0.4);
				transition-timing-function: cubic-bezier(0, 0, 0, 1.25);
			}
		}

		@supports not(d: path("")) {
			.theme-svg {
				& :first-child path {
					transform: translate3d(-9px, 14px, 0);
				}
			}
		}
	}
}
</style>