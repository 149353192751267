<script>
import { ref, watch, nextTick, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { preloader } from './utils/preloader'
import { scroll } from './utils/scroll'
import { spinner } from './utils/spinner'
import { realVH } from './utils/realVH'
import Icon from 'iconly'

import Layout from './layout/Layout.vue'

export default {
	name: 'App',
	components: {
		Layout
	},
	setup() {
		const isFirstLoad = ref(true)
		const router = useRouter()
		const store = useStore()

		const overlayDuration = computed(() => store.state.overlayDuration)
		const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

		const plugins = () => {
			scroll.smooth.init()
			realVH()
			new Icon({ file: './icons.svg' })
		}

		const initialize = async () => {
			await plugins()
			await preloader.animation() // подхватываем статичный прелоадер, анимируем его исчезновение
			await delay(preloader.totalDuration() - 125) // ждем пока отыграет анимация исчезновения прелоадера
			await router.isReady() // убедимся, что роутер полностью инициализирован
			await store.commit('setOverlayVisible', false) // убираем оверлей
			await delay(overlayDuration.value - 300) // ждем пока оверлей исчезнет
			await store.commit('setPageReady', true) // страница полностью готова, сообщаем об этом в стор
		}

		const routeLeave = async () => {
			if (!isFirstLoad.value) {
				await store.commit('setOverlayVisible', true)
				await store.commit('setDrawerVisible', false)
				await spinner.start()
				await delay(overlayDuration.value)
				await store.commit('setPageReady', false)
			}
		}

		const routeEnter = async () => {
			if (isFirstLoad.value) {
				isFirstLoad.value = false
			} else {
				await delay(50)
				await spinner.end()
				await store.commit('setOverlayVisible', false)
				await store.commit('setDrawerVisible', false)
				await delay(overlayDuration.value)
				await nextTick()
				await store.commit('setPageReady', true)
			}
		}

		onMounted(() => {
			initialize()

			router.beforeEach(async (to, from, next) => {
				await routeLeave()
				next()
			})

			router.afterEach(async (to, from) => {
				await routeEnter()
			})
		})

		watch(
			() => router.currentRoute.value,
			(to) => {
				document.title = to.meta.title
				document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)
			}
		)
	}
}
</script>

<template>
	<Layout>
		<routerView></routerView>
	</Layout>
</template>
