import Lenis from '@studio-freight/lenis'
import MediaTrigger from 'media-trigger'
import { mediaTouch } from './matchMedia'

const scroll = {
	lenis: null,
	smooth: {
		create: function() {
			this.lenis = new Lenis({ smoothWheel: true })

			const scrollSmooth = (time) => {
				if (!this.lenis) return

				this.lenis.raf(time)
				requestAnimationFrame(scrollSmooth)
			}

			requestAnimationFrame(scrollSmooth)
		},
		destroy: function() {
			if (!this.lenis) return

			this.lenis.destroy()
			this.lenis = null
		},
		start: function() {
			if (!this.lenis) return

			this.lenis.start()
		},
		stop: function() {
			if (!this.lenis) return

			this.lenis.stop()
		},
		init: function() {
			new MediaTrigger({
				media: mediaTouch,
				entry: () => {
					this.create()
				},
				exit: () => {
					this.destroy()
				}
			})
		}
	}
}

export {
	scroll
}