<script>
import { ref, computed, watch, nextTick, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

import MediaTrigger from 'media-trigger'
import { mediaTouch, mediaBreakpoint } from '../utils/matchMedia'
import { scroll } from '../utils/scroll'
import { lock, unlock } from 'tua-body-scroll-lock'

import Image from './Image.vue'
import Icon from './Icon.vue'
import CallForm from './CallForm.vue'

export default {
	name: 'ModalCall',
	components: {
		Image,
		Icon,
		CallForm
	},
	setup() {
		const modalRef = ref(null)
		const showModalSide = ref(true)

		const store = useStore()

		const isModalCall = computed(() => store.state.isModalCall)

		const toggleModalCall = () => {
			const newModalCallState = !isModalCall.value

			if (!newModalCallState) {
				modalRef.value.classList.add('modal--out')

				setTimeout(() => {
					store.commit('setModalCall', newModalCallState)
				}, 600)
			} else {
				store.commit('setModalCall', newModalCallState)
			}
		}

		const opensModalCall = () => {
			setTimeout(() => {
				modalRef.value.classList.add('modal--in')
			}, 50)

			nextTick(() => {
				if (window.matchMedia(mediaTouch).matches) {
					scroll.smooth.stop()
				} else {
					lock(modalRef.value)
				}
			})
		}

		const closesModalCall = () => {
			if (window.matchMedia(mediaTouch).matches) {
				scroll.smooth.start()
			} else {
				unlock(modalRef.value)
			}
		}

		onMounted(() => {
			new MediaTrigger({
				media: mediaBreakpoint,
				entry: () => {
					showModalSide.value = true
				},
				exit: () => {
					showModalSide.value = false
				}
			})
		})

		onBeforeUnmount(() => {
			if (window.matchMedia(mediaTouch).matches) {
				scroll.smooth.start()
			} else {
				unlock(modalRef.value)
			}
		})

		watch(isModalCall, (newValue, oldValue) => {
			oldValue && !newValue ? closesModalCall() : opensModalCall()
		})

		return {
			modalRef,
			isModalCall,
			toggleModalCall,
			showModalSide
		}
	}
}
</script>

<template lang="html">
	<Teleport to="body">
		<div class="modal" ref="modalRef" v-if="isModalCall" data-lenis-prevent>
			<div class="modal__backdrop" @click="toggleModalCall"></div>
			<div class="modal__scroller">
				<div class="modal__container">
					<div class="modal-content">
						<AppButton
							class="modal-content__close"
							@click="toggleModalCall"
							size="md"
							theme="white"
							aria-label="Закрыть"
							label=""
						>
							<Icon id="i-custom-close" />
						</AppButton>
						<div class="modal-content__side" v-if="showModalSide">
							<Image data-parallax
								:sources="[
									{
										srcset: 'bin/avif/img_call_009_340w_600h.avif 340w, bin/avif/img_call_009_510w_900h.avif 510w, bin/avif/img_call_009_680w_1200h.avif 680w',
										sizes: '(max-width: 340px) 100vw, 340px',
										type: 'image/avif'
									},
									{
										srcset: 'bin/webp/img_call_009_340w_600h.webp 340w, bin/webp/img_call_009_510w_900h.webp 510w, bin/webp/img_call_009_680w_1200h.webp 680w',
										sizes: '(max-width: 340px) 100vw, 340px',
										type: 'image/webp'
									},
									{
										srcset: 'bin/jpg/img_call_009_340w_600h.jpg 340w, bin/jpg/img_call_009_510w_900h.jpg 510w, bin/jpg/img_call_009_680w_1200h.jpg 680w',
										sizes: '(max-width: 340px) 100vw, 340px',
										type: 'image/jpeg'
									}
								]"
								default-src="bin/jpg/img_call_009_680w_1200h.jpg"
								loading="eager"
								decoding="auto"
							/>
						</div>

						<div class="modal-content__call">
							<div class="modal-content__call-text">
								<h4 class="heading-display-4">+7 (495) 139 30 30</h4>

								<p class="paragraph-lead">
									<span class="text-small">Заполните простую форму и&nbsp;мы&nbsp;сами свяжемся с&nbsp;вами как можно скорее</span>
								</p>
							</div>

							<CallForm />
						</div>
					</div>
				</div>
			</div>
		</div>
	</Teleport>
</template>

<style lang="scss" scoped>
@import '../assets/scss/base/mixins';

.modal {
	position: fixed;
	inset: 0;
	z-index: var(--z-index-drawer);
	width: 100vw; min-height: 100%;
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	&__backdrop {
		position: fixed;
		inset: 0;
		z-index: 1;
		background: hsla(200 2% 6% / 1);
		opacity: 0;

		.modal--in & {
			opacity: .92;
			transition: opacity 400ms cubic-bezier(0, 0, 0.5, 1);
		}

		.modal--out & {
			opacity: 0;
			transition: opacity 550ms cubic-bezier(.24, 0, .71, 1.01);
		}
	}

	&__scroller {
		pointer-events: none;
		position: relative;
		z-index: 2;
		min-height: 100%;
		display: grid;
		place-content: center;
	}

	&__container {
		pointer-events: none;
		position: relative;
		z-index: 3;
		width: 100vw;
		max-width: 60rem;
		padding: 2rem;

		@include min-width(tablet-portrait) {
			padding: 3rem;
			max-width: 88rem;
		}

		@include min-width(tablet-landscape) {
			padding: 4rem;
			max-width: 96rem;
		}
	}
}

.modal-content {
	pointer-events: auto;
	position: relative;
	width: 100%;
	border-radius: 1px;
	background-color: var(--c-light);
	opacity: 0;
	transform: translateY(-40px);
	display: flex;

	.modal--in & {
		opacity: 1;
		transform: translateY(0);
		transition:
			opacity 400ms cubic-bezier(.61, 1, .88, 1),
			transform 400ms cubic-bezier(.61, 1, .88, 1);
	}

	.modal--out & {
		opacity: 0;
		transform: translateY(60px);
		transition:
			opacity 500ms cubic-bezier(0, 0, 0.5, 1),
			transform 550ms cubic-bezier(.22, 1, .5, .95);
	}

	&__close {
		position: absolute;
		top: 0; right: 0;
	}

	&__side {
		position: relative;
		min-width: 34rem;
	}

	&__call {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		flex: 1;
		padding: 4rem 2.5rem;

		@include min-width(phone-portrait) {
			padding: 4rem 3rem;
		}

		@include min-width(phone-landscape) {
			padding: 4rem;
		}

		@include min-width(tablet-portrait) {
			padding: 5rem;
		}

		@include min-width(tablet-landscape) {
			padding: 3.5rem 5rem;
		}

		&-text {
			padding-bottom: 3.5rem;
			color: var(--c-dark);

			h4.heading-display-4 {
				margin-bottom: .6em;
				font-size: 3.6em;
				font-weight: 800;
			}

			p.paragraph-lead {
				max-width: 39rem;
			}
		}
	}
}
</style>