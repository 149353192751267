<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'Hamburger',
	setup() {
		const isThrottled = ref(false)
		const store = useStore()

		const isDrawerVisible = computed(() => store.state.isDrawerVisible)
		const overlayDuration = computed(() => store.state.overlayDuration)

		const drawerState = () => {
			if (!isThrottled.value) {
				isThrottled.value = true

				setTimeout(() => {
					isThrottled.value = false
				}, 1700)

				if (isDrawerVisible.value) {
					store.commit('setDrawerVisible', false)
					setTimeout(() => { store.commit('setOverlayVisible', false) }, overlayDuration.value / 3)
				} else {
					store.commit('setDrawerVisible', true)
					store.commit('setOverlayVisible', true)
				}
			}
		}

		return {
			drawerState
		}
	}
}
</script>

<template>
	<div class="hamburger-container">
		<div class="hamburger-container__body">
			<div
				@click="drawerState"
				aria-expanded="false"
				class="hamburger"
				data-cursor-style="big-bubble"
				aria-label="Меню"
				role="button">
				<span class="hamburger__line"></span>
				<span class="hamburger__line"></span>
				<span class="hamburger__line"></span>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '../assets/scss/base/mixins';

.hamburger-container {
	position: fixed;
	top: 0; right: 0; bottom: auto; left: 0;
	z-index: var(--z-index-hamburger);
	@include fixJumpingScrollbar;

	mix-blend-mode: exclusion;
	pointer-events: none;

	body.is-drawer & {
		--z-index-hamburger: 360;
	}

	&__body {
		pointer-events: none;
		@include layoutCenter;
		height: var(--headerHeight);
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

.hamburger {
	--ham-size: 50px;
	--ham-delay: 225ms;

	position: relative;
	width: var(--ham-size); height: var(--ham-size);
	transform: translateX(2px);
	transform-origin: center;
	pointer-events: auto;
	cursor: pointer;

	@include min-width(tablet-landscape) {
		transform: translateX(0);
		border: 2px solid var(--c-text-contrast);
	}

	&__line {
		pointer-events: none;
		@include absoluteCenter(0, 0, 0, 0);
		transform-origin: center;
		display: block;
		width: 22px; height: 2px;
		background-color: White;

		transition:
			transform var(--ham-delay) cubic-bezier(0.5, 0, 0, 1),
			top var(--ham-delay) cubic-bezier(0.5, 0, 0, 1) var(--ham-delay),
			bottom var(--ham-delay) cubic-bezier(0.5, 0, 0, 1) var(--ham-delay),
			width var(--ham-delay) cubic-bezier(0.5, 0, 0, 1),
			background-color var(--ham-delay) cubic-bezier(0.5, 0, 0, 1);

		&:first-child {
			width: 16px;
			top: 14px;
			transform: translateX(3px);
		}

		&:last-child {
			width: 8px;
			bottom: 14px;
			transform: translateX(7px);
		}

		body.is-drawer & {
			transition:
				transform var(--ham-delay) cubic-bezier(0.5, 0, 0, 1) var(--ham-delay),
				top var(--ham-delay) cubic-bezier(0.5, 0, 0, 1),
				bottom var(--ham-delay) cubic-bezier(0.5, 0, 0, 1),
				width var(--ham-delay) cubic-bezier(0.5, 0, 0, 1) var(--ham-delay),
				background-color var(--ham-delay) cubic-bezier(0.5, 0, 0, 1);

			&:first-child {
				width: 22px;
				top: 0;
				transform: rotate(-45deg) translateX(0);
			}

			&:last-child {
				width: 22px;
				bottom: 0;
				transform: rotate(45deg) translateX(0);
			}

			&:not(:first-child):not(:last-child) {
				background-color: transparent;
			}
		}
	}
}
</style>